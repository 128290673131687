import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"

class NonPenGoals extends React.Component {

  state = {
    showTopLevel: false,
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Goals Without Penalties"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        Math.round(mdata.filter(d => d.node.competition === comp).map(d => Math.round(d.node.minsPlayed / (d.node.goals - d.node.pens)) ))
      ) : (
        Math.round(rdata.filter(d => d.node.competition === comp).map(d => Math.round(d.node.minsPlayed / (d.node.goals - d.node.pens)) ))
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    // const [{goals: rtest}] = this.props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === 'All Time Career').map(d => d.node)

    //console.log(comps)

    return (

      <Layout>
        <SEO
          title={`Non-penalty Goals - Messi vs Ronaldo All Goals Without Penalties`}
          description={`Penalties are a type of goal unlike any other - what does the Messi vs Ronaldo goal count look like without them?`}
          canonicalPath={`/detailed-stats/goals-without-penalties/`}
        />

        <h1>Non-penalty Goals <span className="sr-only">Messi and Ronaldo All Goals Without Penalties</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>
          
          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              title={d.title ? d.title : d.comp}
              topLevel={d.topLevel}
              showTopLevel={this.state.showTopLevel}
              playerTitleHidden={`${d.comp} ${pageTitle}`}
              statType={pageTitle}
              btnText={d.btnText}
              statView={['Totals', 'Mins Per Non-penalty Goal', 'Pens as % of Total Goals']}
              mSubStat={`scores a non-penalty goal every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} mins`}
              rSubStat={`scores a non-penalty goal every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} mins`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
            />
          ))}

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          pens
          goals
        }
      }
    }
  }
`

export default NonPenGoals
